html,
body {
    box-sizing: border-box;
    margin: 0;
}

.empty {
    height: calc(100vh - 130px);
}

.chip {
    background-color: #f9a468;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    font-size: 11px;
    font-weight: 700;
    margin: 0 10px;
    /* padding: 2px 10px; */
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

img {
    max-width: 100%;
}

.rounded-full {
    border-radius: 50%;
}

.avatarUpload {
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.avatarUpload img {
    height: 100%!important;
    width: auto!important;
    max-width: initial;
}